<!--
  * @Descripttion:
  * @version:
  * @Author: GxG
  * @Date: 2023-03-15 11:11:11
 * @LastEditors: Toby toby@gmail.com
 * @LastEditTime: 2023-09-26 18:55:56
-->
<template>
  <div class="star-main-box" v-if="!ismobile">
    <div class="node-info" v-if="nodeTotal > 0 && showNode">
      <div class="flexcenter" @click="$router.push('/point')">
        {{ $t("point.desc1", { n1: nodeTotal }) }}
      </div>
    </div>

    <div class="notice-top flex" v-if="noticeInfo.id != undefined" @click="handlerPath(noticeInfo.forwardPath)">
      <img src="../../public/images/noticeicon.png" />
      <div class="ellipse">{{ noticeInfo.title }}</div>
    </div>
    <!-- 星球webgl -->
    <div ref="threeStar"></div>
    <!-- 星球提示框 -->
    <div class="star-alert-box star-alert-bb" ref="starEl" v-if="starInfo != null">
      <div class="star-alert-content" :class="{ start1: starInfo.status == 0, start2: starInfo.status == 1 }">
        <div class="star-alert-text-box flex-x-start">
          <div class="star-alert-title">
            {{ $t("home.desc50") }} <span>{{ starInfo.typeName }}</span>
          </div>
        </div>

        <div class="star-alert-text-box flex-x-start" v-if="starInfo.status == 2">
          <div class="star-alert-title">
            {{ $t("planet.desc2") }}: <span>{{ $t("planet.desc69") }}</span>
          </div>
        </div>
        <div class="star-alert-text-box flex-x-start" v-else-if="starInfo.status == 0">
          <div class="star-alert-title">
            {{ $t("planet.desc2") }}: <span>{{ $t("home.desc62") }}</span>
          </div>
        </div>
        <div class="star-alert-text-box flex-x-start" v-else>
          <div class="star-alert-title">
            {{ $t("home.desc60") }} <span>{{ starInfo.planetName }}</span>
          </div>
        </div>
        <div class="star-alert-text-box flex-x-start" v-if="starInfo.status == 1">
          <div class="star-alert-title">
            {{ $t("land.desc4") }} <span>{{ starInfo.userName }}</span>
          </div>
        </div>
        <!-- <div class="star-alert-text-box flex-x-start" v-if="starInfo.status == 2">
          <div class="star-alert-title">{{ $t('home.desc60') }} <span>{{ $t('home.desc61') }}</span></div>
        </div> -->
        <div class="star-alert-text-box flex-x-start">
          <div class="star-alert-title">
            {{ $t("home.desc52") }}
            <span>{{ starInfo.track }}-{{ starInfo.no }}</span>
          </div>
        </div>
        <div class="star-alert-text-box flex-x-start">
          <div class="star-alert-title">
            {{ $t("home.desc53") }} <span>{{ starInfo.availableLand }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 左侧选择框 -->
    <div class="select-box flex-x-start" :class="{ 'select-left': isShowSlect, 'topmax': activityTop }">
      <div ref="selectHei" class="select-le" :style="`height:${selectH}px`">
        <!-- owner  请在此处加 登录判断条件 登录后才可以显示 I own-->
        <div class="select-list-box" v-if="1">
          <div class="select-list flex-x-between">
            <div class="select-list-title">{{ ownerList[0].title }}</div>
            <div :class="ownerList[0].isSelect
              ? 'select-list-select select-acitve flex-y-center'
              : 'select-list-select flex-y-center'
              " @click="
    ownerList[0].isSelect =
    ownerList[0].isSelect == true ? false : true;
  starFilter(undefined, undefined, ownerList[0].isSelect);
  "></div>
          </div>
        </div>

        <!-- age -->
        <!-- <div class="select-list-box">
          <div class="select-list flex-x-between" v-for="(item, index) in ageList" :key="index">
            <div class="select-list-title">{{ item.name }}</div>
            <div
              :class="item.isSelect ? 'select-list-select select-acitve flex-y-center' : 'select-list-select flex-y-center'"
              @click="ownerFun('ageList', 'isSelect', index); starFilter(item.id)"></div>
          </div>
        </div>
        <div class="select-list-box">
          <div class="select-list flex-x-between" v-for="(item, index) in whetherList" :key="index">
            <div class="select-list-title">{{ item.name }}</div>
            <div
              :class="item.isSelect ? 'select-list-select select-acitve flex-y-center' : 'select-list-select flex-y-center'"
              @click="ownerFun('whetherList', 'isSelect', index); starFilterName(item.id)"></div>
          </div>
        </div> -->
        <!-- 星球坐标 -->
        <div class="select-list-box" @click="isShowTrackSelcet = 0">
          <div class="coordinate-title">{{ $t("node.desc20") }}</div>
          <div class="coordinate-select">
            <el-select v-model="typeId" :placeholder="$t('planet.desc1')">
              <el-option :value="item.id" :label="item.name" v-for="(item, index) in ageList" :key="item.id"></el-option>
            </el-select>
          </div>
          <div class="coordinate-title">{{ $t("planet.desc2") }}</div>
          <div class="coordinate-select">
            <el-select v-model="statusId" :placeholder="$t('planet.desc1')">
              <el-option :value="item.id" :label="item.name" v-for="(item, index) in whetherList"
                :key="item.id"></el-option>
            </el-select>
          </div>
          <div class="coordinate-title">{{ $t("home.desc54") }}</div>
          <div class="coordinate-track-box flex-x-between">
            <div class="coordinate-le">
              <div class="coordinate-le-title">{{ $t("home.desc55") }}</div>
              <div class="coordinate-select">
                <el-select v-model="trackPlant.track" filterable :placeholder="$t('planet.desc1')">
                  <el-option :value="item" :label="item" v-for="(item, index) in trackNoList" :key="item.id"></el-option>
                </el-select>
              </div>
              <!-- <div
                :class="isShowTrackSelcet == 1 ? 'coordinate-le-numbe numbe-acetive flex-x-between' : 'coordinate-le-numbe flex-x-between'"
                @click.stop="isShowTrackSelcet = 1">
                <div class="coordinate-le-numbe-text">{{ trackPlant.track }}</div>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5" d="M8 0H0L4.30769 5L8 0Z" fill="white" />
                </svg>
              </div>

              <div class="coordinate-position" v-show="isShowTrackSelcet == 1">
                <div class="coordinate-text" v-for="(item, index) in trackNoList" :key="index"
                  @click="trackPlant.track = item">{{ item }}</div>
              </div> -->
            </div>

            <div class="coordinate-le">
              <div class="coordinate-le-title">{{ $t("home.desc56") }}</div>
              <div class="coordinate-select">
                <el-select v-model="trackPlant.no" filterable :placeholder="$t('planet.desc1')">
                  <el-option :value="item" :label="item" v-for="(item, index) in trackNoList" :key="item.id"></el-option>
                </el-select>
              </div>
              <!-- <div
                :class="isShowTrackSelcet == 2 ? 'coordinate-le-numbe numbe-acetive flex-x-between' : 'coordinate-le-numbe flex-x-between'"
                @click.stop="isShowTrackSelcet = 2">
                <div class="coordinate-le-numbe-text">{{ trackPlant.no }}</div>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5" d="M8 0H0L4.30769 5L8 0Z" fill="white" />
                </svg>
              </div>

              <div class="coordinate-position" v-show="isShowTrackSelcet == 2">
                <div class="coordinate-text" v-for="(item, index) in trackNoList" :key="index"
                  @click="trackPlant.no = item">{{ item }}</div>
              </div> -->
            </div>
          </div>

          <div class="coordinate-starid">
            <div class="coordinate-title">{{ $t("home.desc57") }}</div>
            <input type="text" class="coordinate-starid-input" :placeholder="$t('home.desc57')"
              v-model="trackPlant.starId" />
          </div>

          <div class="coordinate-but">
            <div class="coordinate-but-bg" @click="starFilterId">
              {{ $t("home.desc58") }}
            </div>
          </div>

          <div class="coordinate-clear" @click="clearFun()">
            {{ $t("home.desc59") }}
          </div>
        </div>
      </div>

      <div :class="isShowSlect
        ? 'select-ri flex-y-center'
        : 'select-ri flex-y-center select-ri-rotate'
        " @click.stop="isShowSlect = isShowSlect ? false : true">
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.53516 8.53516L5.53516 0.535156L0.464733 4.53516L5.53516 8.53516Z" fill="white" />
        </svg>
      </div>
    </div>
  </div>
  <div class="h5home-con" v-else></div>
  <!-- 星球详情 -->
  <nodeWrappar v-if="isStarDetails" :nodeId="nodeId" :level="level" @handlerClose="handlerClose"
    @handlerBuy="handlerBuy" />
  <marketInfo :planetId="nodeId" :levelId="level" @handlerClose="handlerClose1" :type="1" v-if="showInfo1" />
  <!-- 底部 -->
  <!-- <daotBuyPopup v-model:show="showBuyPopup"/> -->
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watchEffect,
  computed,
  onBeforeUnmount,
} from "vue";
import * as THREE from "three";
import TrackballControls from "three-trackballcontrols";
import nodeWrappar from "../../componets/nodeInfo.vue";
import imgMt4 from "../../public/images/mt4.png";
import marketInfo from "../../componets/marketInfo.vue";
import i18n from "@/public/lang/i18n";
import store from "../../store";

export default {
  name: "star",
  components: { nodeWrappar, marketInfo },
  props: ["startTypeId", "track", "no", 'showActivity'],
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const threeStar = ref(null);
    const selectHei = ref(null);
    const starEl = ref(null);
    const TWEEN = require("three-tween");

    const state = reactive({
      showInfo1: false,
      ismobile: false,
      showNode: false,
      pageType: "buy",
      noticeInfo: {},
      nodeTotal: 0,
      nodeId: "",
      level: "",
      typeId: 0,
      statusId: 0,
      activityTop: false,
      ownerList: [
        // {title:'On sale', isSelect:true,},
        { title: i18n.global.t("node.desc72"), isSelect: false },
      ],
      ageList: [
        { name: i18n.global.t("planet.desc40"), isSelect: true, id: 0 },
      ],
      whetherList: [
        { name: i18n.global.t("planet.desc40"), isSelect: true, id: 0 },
        { name: i18n.global.t("planet.desc69"), isSelect: false, id: 3 },

        { name: i18n.global.t("planet.desc68"), isSelect: false, id: 2 },
        { name: i18n.global.t("planet.desc67"), isSelect: false, id: 1 },
      ],
      isStarDetails: false, //详情组件显示隐藏
      isShowSlect: true, //选择列表是否显示
      selectH: 0, //左侧筛选高度
      trackList: ["ALL"], //轨道
      trackNoList: ["ALL"], //第几个星球
      trackPlant: {
        //存储选择轨道数据
        track: "ALL",
        no: "ALL",
        starId: "",
      },
      isShowTrackSelcet: 0, //是否显示track和trackNumber 选择列表 0不显示，1显示track, 2显示track上第几个
      starInfo: null, //星球弹窗信息
      starDetailsId: "", //详情参数id
      eraId: "", //详情土地id
      soldNumber: "", //星球弹窗 出售未出售数量
      throttling: false, //节流
      soldPrestore: [], // 缓存星球详情，10秒更新一次

      showBuyPopup: false
    });
    // *********************************************************************************************************************************************************** //
    // **                                                              子组件回调方法                                                                             ** //
    // *********************************************************************************************************************************************************** //
    // 关闭按钮
    /**
     * @params {t} Number 自定义参数： t==0 关闭
     */
    const handlerClose = function (t) {
      context.emit("activityBtn", 0);
      state.isStarDetails = false;
      state.isShowSlect = true;
      mouseMovePlanet = -1;
      tweenFun(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    };
    const handlerBuy = function () {
      state.isStarDetails = false;
      state.isShowSlect = true;
      mouseMovePlanet = -1;
      tweenFun(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      setTimeout(() => {
        state.showInfo1 = true;
        context.emit("activityBtn", 1);
      }, 500);
    };
    const handlerClose1 = function () {
      context.emit("activityBtn", 0);
      state.showInfo1 = false;
    };
    // *********************************************************************************************************************************************************** //
    // **                                                           	   页面逻辑                                                                                ** //
    // *********************************************************************************************************************************************************** //
    // 简单排序
    const sortArray = function (array) {
      if (array instanceof Array) {
        array.sort(function (a, b) {
          return a - b;
        });
      }
    };
    //获取星球节点数

    // 筛选框筛选
    /**
     * @params {array}      String 自定义参数： 数组名称
     * @params {attribute}  String 自定义参数： 属性名称
     * @params {index}      Number 自定义参数： 数组下标
     */
    const ownerFun = function (array, attribute, index) {
      if (typeof index != "number") return;
      state[array].forEach((element, jIndex) => {
        if (index == jIndex) {
          element[attribute] = true;
        } else {
          element[attribute] = false;
        }
      });
    };

    // 清空搜索内容
    const clearFun = function () {
      state.trackPlant = {
        track: "ALL",
        no: "ALL",
        starId: "",
      };
      starFilterId();
    };

    // 左侧筛选条高度检测
    const leftSelectHeightFun = function () {
      let timer = setInterval(() => {
        if(selectHei.value){
          let selectH = selectHei.value.scrollHeight;
          let windowH = window.innerHeight;
          if (selectH + 130 > windowH) {
            state.selectH = windowH - 140;
          } else {
            state.selectH = selectH;
          }
          clearInterval(timer);
        }
      }, 50);
    };

    //星球坐标信息显示卡片
    /**
     * @params {isShow}     Boolean 自定义参数： 是否显示卡片
     * @params {cardX}      Number 自定义参数： 卡片的X轴坐标
     * @params {cardY}      Number 自定义参数： 卡片的Y轴坐标
     */
    const starInfoCard = function (isShow, cardX, cardY) {
      if (typeof isShow != "boolean") return;
      if (starEl.value == null) return;
      let myElement = starEl.value;
      let windowW = window.innerWidth;
      let windowH = window.innerHeight;
      let starElW = 244;
      let starElH = 112;
      if (isShow) {
        if (cardX > windowW - starElW && cardY < starElH) {
          // 右上角
          myElement.className = "star-alert-box star-alert-rr";
          starEl.value.style.top = cardY - 10 + "px";
          starEl.value.style.left = cardX - starElW + "px";
        } else if (cardX > windowW - starElW && cardY > windowH - starElH) {
          // 右下角
          myElement.className = "star-alert-box star-alert-rb";
          starEl.value.style.top = cardY - starElH + 25 + "px";
          starEl.value.style.left = cardX - starElW + "px";
        } else if (cardX > windowW - starElW) {
          // 右
          myElement.className = "star-alert-box star-alert-rr";
          starEl.value.style.top = cardY - 10 + "px";
          starEl.value.style.left = cardX - starElW + "px";
        } else if (cardY > windowH - starElH) {
          // 下
          myElement.className = "star-alert-box star-alert-bb";
          starEl.value.style.top = cardY - starElH + 25 + "px";
          starEl.value.style.left = cardX + 50 + "px";
        } else {
          myElement.className = "star-alert-box";
          starEl.value.style.top = cardY - 10 + "px";
          starEl.value.style.left = cardX + 50 + "px";
        }
        starEl.value.style.display = "block";
      } else {
        starEl.value.style.display = "none";
      }
    };

    // 星球数据筛选
    /**
     * @params {eraId}                        Number 自定义参数： 时代id  用于时代单选框
     * @params starInfo={track, no, starId}   Number 自定义参数： 星球坐标参数
     * @params {isOwn}                   Boolean 自定义参数： true 有我的土地， false无
     */
    const starFilter = function (eraId, starInfo, isOwn) {
      for (var i = 0; i < groupArray.children.length; i++) {
        groupArray.children[i].children[0].children.forEach(function (element) {
          if (element.intr && typeof element.intr == "object") {
            // 时代筛选条件
            if (eraId != undefined) {
              if (eraId !== element.intr.typeId && eraId != 0) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            }
            // 星球坐标 组合搜索没有优先级
            if (starInfo != undefined) {
              element.visible = false;
              if (
                starInfo.track == "ALL" &&
                starInfo.no == "ALL" &&
                starInfo.starId == ""
              ) {
                element.visible = true;
              } else if (
                starInfo.track == element.intr.track &&
                starInfo.no == "ALL" &&
                starInfo.starId == ""
              ) {
                element.visible = true;
              } else if (
                starInfo.track == "ALL" &&
                starInfo.no == element.intr.no &&
                starInfo.starId == ""
              ) {
                element.visible = true;
              } else if (
                starInfo.track == element.intr.track &&
                starInfo.no == element.intr.no &&
                starInfo.starId == ""
              ) {
                element.visible = true;
              }
            }

            //是否有我的土地
            if (isOwn != undefined) {
              if (element.intr.isOwn != isOwn && isOwn) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            }
          }
        });
      }
    };
    //星球命名筛选
    const starFilterName = (eraId) => {
      let startId = 99;
      if (eraId == 0) {
        startId = 99;
      } else if (eraId == 1) {
        startId = 0;
      } else if (eraId == 2) {
        startId = 1;
      } else {
        startId = 2;
      }
      for (var i = 0; i < groupArray.children.length; i++) {
        groupArray.children[i].children[0].children.forEach(function (element) {
          if (element.intr && typeof element.intr == "object") {
            // 时代筛选条件
            if (startId != 99) {
              if (startId !== element.intr.status) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            } else {
              element.visible = true;
            }
          }
        });
      }
    };
    const starFilterId = () => {
      for (var i = 0; i < groupArray.children.length; i++) {
        groupArray.children[i].children[0].children.forEach(function (element) {
          if (element.intr && typeof element.intr == "object") {
            // 时代筛选条件

            if (state.trackPlant.starId != "") {
              if (state.trackPlant.starId != element.intr.id) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            } else if (
              state.trackPlant.starId == "" &&
              state.trackPlant.track != "ALL" &&
              state.trackPlant.no == "ALL"
            ) {
              if (state.trackPlant.track != element.intr.track) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            } else if (
              state.trackPlant.starId == "" &&
              state.trackPlant.track == "ALL" &&
              state.trackPlant.no != "ALL"
            ) {
              if (state.trackPlant.no != element.intr.no) {
                element.visible = false;
              } else {
                element.visible = true;
              }
            } else if (
              state.trackPlant.starId == "" &&
              state.trackPlant.track != "ALL" &&
              state.trackPlant.no != "ALL"
            ) {
              if (
                state.trackPlant.no == element.intr.no &&
                state.trackPlant.track == element.intr.track
              ) {
                element.visible = true;
              } else {
                element.visible = false;
              }
            } else {
              element.visible = true;
            }

            if (element.visible) {
              let eraId = 99;
              if (state.statusId == 0) {
                eraId = 99;
              } else if (state.statusId == 1) {
                eraId = 0;
              } else if (state.statusId == 2) {
                eraId = 1;
              } else {
                eraId = 2;
              }
              if (element.intr.typeId != state.typeId && state.typeId != 0) {
                element.visible = false;
              } else if (element.intr.status != eraId && state.statusId != 0) {
                element.visible = false;
              }
            }

            // starFilter(state.typeId)
            // starFilterName(state.statusId)
          }
        });
      }
    };
    //选择星系

    // *********************************************************************************************************************************************************** //
    // **                                                                接口方法                                                                                ** //
    // *********************************************************************************************************************************************************** //
    // 获取星球接口
    const getStarList = function () {
      proxy
        .$post(proxy.URL.star.list, {
          planetGalacticId: props.startTypeId,
          track: props.track,
          no: props.no,
        })
        .then((res) => {
          if (res.code == 0 && res.data != null) {
            // console.log(res.data)
            // chunk(res.data,300)
       
            if (res.data.length > 0) {
              pushPlanets(res.data);
            }

    
            // setTimeout(() => {
            //   pushPlanets(newArray[1][0])
            // }, 15000);
          }
        });
    };
    const add = (data) => {
      var R = 55;
      for (var key in data) {
        !(function (t, keys) {
          setTimeout(function () {
            let rotationz = 0;
            if (t.track % 2 == 0) {
              rotationz = 0.1;
            }
            if (t.track % 3 == 0) {
              rotationz = 0.2;
            }
            if (t.track % 4 == 0) {
              rotationz = -0.1;
            }
            if (t.track % 5 == 0) {
              rotationz = -0.2;
            }
            renderFunPlent(t, R, 4, 0, 0, rotationz);
            if (keys == data.length - 1) {
              setTimeout(function () {
                render();
              });
            }
          }, 0);
        })(data[key], key);
      }
    };
    // 添加星球到数组内
    var geometry = null;
    const pushPlanets = function (data) {
      var R = 50;
      geometry = new THREE.SphereGeometry(4, 4 * 10, 4 * 10);
      if (typeof data == "object") {
        for (var key in data) {
          !(function (t, keys) {
            setTimeout(function () {
              let rotationz = 0;
              if (t.track % 2 == 0) {
                rotationz = 0.1;
              }
              if (t.track % 3 == 0) {
                rotationz = 0.2;
              }
              if (t.track % 4 == 0) {
                rotationz = -0.1;
              }
              if (t.track % 5 == 0) {
                rotationz = -0.2;
              }
              renderFunPlent(t, R, 4, 0, 0, rotationz);
              if (keys == data.length - 1) {
                setTimeout(function () {
                  render();
                });
              }
            }, 0);
          })(data[key], key);
        }
      }
    };
    const chunk = (arr, num) => {
      let j = 0,
        o = j;
      let newArray = [];
      while (j < arr.length) {
        j += num;
        newArray.push([arr.slice(o, j)]);
        o = j;
      }
      return newArray;
    };
    //获取时代列表
    const getAgeList = function () {
      proxy.$post(proxy.URL.star.type, {}).then((res) => {
        if (res.code == 0 && res.data != null) {
          res.data.forEach((element, index) => {
            element.isSelect = false;
            state.ageList.push(element);
            if (res.data.length == index + 1) {
              setTimeout(() => {
                leftSelectHeightFun();
              }); //获取高度
            }
          });
        }
      });
    };

    // 获取详情列表 解决无法从接口得到土地出售未出售数量问题
    /**
     * @params {id} String 自定义参数： 星球id
     */
    const getDetails = function (id) {
      if (state.throttling) return;
      state.throttling = true;
      proxy
        .$post(proxy.URL.star.getDetails, {
          planetId: id,
        })
        .then((res) => {
          state.throttling = false;
          if (res.code == 0) {
            state.soldNumber = `${res.data.saleCountLand}/${res.data.totalLand}`;
            let array = state.soldPrestore;
            if (array.length == 0) {
              state.soldPrestore.push({
                id: id,
                soldNumber: `${res.data.saleCountLand}/${res.data.totalLand}`,
                dataTime: Date.now(),
              });
            } else {
              let bol = false,
                oldIndex = 0;
              for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if (element.id && element.id == id) {
                  bol = true;
                  oldIndex = i;
                  break;
                }
              }
              if (bol) {
                state.soldPrestore[
                  oldIndex
                ].soldNumber = `${res.data.saleCountLand}/${res.data.totalLand}`;
                state.soldPrestore[oldIndex].dataTime = Date.now();
              } else {
                state.soldPrestore.push({
                  id: id,
                  soldNumber: `${res.data.saleCountLand}/${res.data.totalLand}`,
                  dataTime: Date.now(),
                });
              }
            }
          }
        });
    };

    // *********************************************************************************************************************************************************** //
    // **                                                           	  three.js                                                                                ** //
    // *********************************************************************************************************************************************************** //
    // 基础对象
    const groupArray = new THREE.Object3D(); //创建一个基本的对象
    const scene = new THREE.Scene(); //创建一个场景
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    ); //创建一个透视相机
    const renderer = new THREE.WebGLRenderer(); //创建一个WebGL渲染器
    const loader = new THREE.TextureLoader(); //用于加载一个纹理对象(Texture)
    const spotLight = new THREE.SpotLight(0xffffff); //创建聚光灯
    const raycaster = new THREE.Raycaster();
    var trackballControls = null; //轨道控制器
    var requestAni = null;

    // 信息变量
    var cameraPosition = { x: 0, y: 30, z: 500 }, //相机位置参数
      mouseMovePlanet = -1, //鼠标移入轨道
      rotationTrack = 0.0005, //轨道自转
      worldPosition = { x: 0, y: 0, z: 0 };
    // 初始化数据
    const init = function () {
      camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      camera.lookAt(new THREE.Vector3(0, 0, 0));
      scene.add(camera);

      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;

      spotLight.position.set(200, 200, 200);
      spotLight.castShadow = true;
      scene.add(spotLight);

      threeStar.value.appendChild(renderer.domElement);

      //threeStar.value.addEventListener('touchstart',onDocumentMouseDown, false);
      if (localStorage.getItem("ismobile") == 0) {
        threeStar.value.addEventListener("click", onDocumentMouseDown, false); // 鼠标点击事件
        threeStar.value.addEventListener(
          "mousemove",
          onDocumentMouseMove,
          false
        ); // 鼠标移动事件
      } else {
        renderer.domElement.addEventListener(
          "pointerdown",
          onDocumentMouseDown,
          false
        ); // 鼠标点击事件
        renderer.domElement.addEventListener(
          "mousemove",
          onDocumentMouseMove,
          false
        ); // 鼠标移动事件
      }

      trackballControls = new TrackballControls(camera, renderer.domElement);
      trackballControls.rotateSpeed = 5;
      trackballControls.zoomSpeed = 5;
      trackballControls.panSpeed = 2.0;
      trackballControls.noPan = true;
      trackballControls.staticMoving = false;
      trackballControls.dynamicDampingFactor = 0.2;
      trackballControls.minDistance = 50;
      trackballControls.maxDistance = 2000;
    };

    // 渲染函数
    const render = function () {
      trackballControls.update();
      TWEEN.update();
      camera.lookAt(
        new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z)
      );
      for (var i = 0; i < groupArray.children.length; i++) {
        if (groupArray.children[i].name != mouseMovePlanet) {
          groupArray.children[i].children[0].rotation.z += rotationTrack;
        }
        groupArray.children[i].children[0].children.forEach(function (element) {
          if (element.intr && typeof element.intr == "object") {
            element.rotation.y -= 0.002;
          }
        });
      }
      requestAni = requestAnimationFrame(render);
      renderer.render(scene, camera);

      //渲染完成
      context.emit("renderEnd");
    };

    /** 渲染星球
     * @param {data}     data      后端返回数据
     * @param {number}   plaRadius 星球半径 起始大小依次累计
     * @param {number}   plaSize   星球大小
     * @param {number}   rotaX     星球trackx
     * @param {number}   rotaY     星球tracky
     * @param {number}   rotaZ     星球trackz
     */

    const renderFunPlent = function (
      data,
      plaRadius,
      plaSize,
      rotaX,
      rotaY,
      rotaZ
    ) {
      let radius = plaRadius * Number(data.track);
      if (state.trackList.indexOf(data.track) == -1) {
        let group = new THREE.Group();
        let trackArc = new THREE.ArcCurve(0, 0, radius, 0, 2 * Math.PI);
        let trackPoints = trackArc.getPoints(550);
        let bufferGeometry = new THREE.BufferGeometry().setFromPoints(
          trackPoints
        );
        let lineBasicMaterial = new THREE.LineBasicMaterial({
          color: 0xffffff,
          opacity: 0.3,
          transparent: true,
        });

        var trackLine = new THREE.Line(bufferGeometry, lineBasicMaterial);
        trackLine.rotation.x = Math.PI / 2;
        group.name = data.track;
        trackLine.name = data.track;

        group.add(trackLine);
        groupArray.add(group);
        state.trackList.push(data.track);
        scene.add(groupArray);
      }
      if (state.trackNoList.indexOf(data.no) == -1) {
        state.trackNoList.push(data.no);
      }
      sortArray(state.trackNoList);
      data.icon =
        data.icon == ""
          ? "https://mystimages.oss-cn-hongkong.aliyuncs.com/mystipfs/test/star/5_1.png"
          : data.icon;
      let material = new THREE.MeshBasicMaterial({
        map: loader.load(data.icon),
      });
      let planetMesh = new THREE.Mesh(geometry, material);
      for (let i = 0, j = 1; i < groupArray.children.length; i++) {
        if (groupArray.children[i].name == data.track) {
          // 加载贴图
          let texture = loader.load(imgMt4);
          // 发光颜色
          let light =
            data.colours == null
              ? "rgba(255,255,255,1)"
              : "rgba(" + data.colours + ")";
          // 点精灵材质
          let spriteMaterial = new THREE.SpriteMaterial({
            map: texture, //贴图
            color: light,
            // rotation:Math.PI/4,
            blending: THREE.AdditiveBlending, //在使用此材质显示对象时要使用何种混合。加法
          });
          let sprite = new THREE.Sprite(spriteMaterial);
          // 发光范围
          sprite.scale.set(15, 15, 1);
          planetMesh.add(sprite);
          planetMesh.intr = data;
          planetMesh.rotation.x = -Math.PI / 2;
          planetMesh.position.x =
            radius * Math.sin(Number(data.track) + Number(data.no));
          planetMesh.position.y =
            radius * Math.cos(Number(data.track) + Number(data.no));
          groupArray.children[i].rotation.set(0, 0, rotaZ);
          groupArray.children[i].children[0].add(planetMesh);
        }
      }
    };

    // 鼠标移入
    const onDocumentMouseMove = function (event) {
      var Sx = event.clientX;
      var Sy = event.clientY;
      var x = (Sx / window.innerWidth) * 2 - 1;
      var y = -(Sy / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
      var intersects = raycaster.intersectObjects(scene.children, true);
      if (
        intersects.length > 0 &&
        intersects[0].object.intr != undefined &&
        intersects[0].object.visible
      ) {
        state.starInfo = intersects[0].object.intr;
        mouseMovePlanet = intersects[0].object.intr.track;
        let array = state.soldPrestore;
        if (array.length == 0) {
          //getDetails(intersects[0].object.intr.typeId);
        } else {
          let bol = false,
            nowData = Date.now(),
            nowIndex = 0;
          for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if (element.id && element.id == intersects[0].object.intr.typeId) {
              nowIndex = i;
              if (nowData - element.dataTime < 10000) {
                bol = true;
              } else {
                bol = false;
              }
              break;
            }
          }
          if (!bol) {
            //getDetails(intersects[0].object.intr.typeId);
          } else {
            state.soldNumber = state.soldPrestore[nowIndex].soldNumber;
          }
        }
        starInfoCard(true, Sx, Sy);
      } else {
        mouseMovePlanet = -1;
        starInfoCard(false);
      }
    };

    // 鼠标点击
    var movTthrottle = true;
    const onDocumentMouseDown = function (event) {
      var Sx = event.clientX;
      var Sy = event.clientY;
      var x = (Sx / window.innerWidth) * 2 - 1;
      var y = -(Sy / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

      var intersects = raycaster.intersectObjects(scene.children, true);
      if (
        movTthrottle &&
        intersects.length > 0 &&
        intersects[0].object.intr != undefined
      ) {
        movTthrottle = false;
        movTthrottle = true;

        state.starDetailsId = intersects[0].object.intr.typeId;
        state.nodeId = intersects[0].object.intr.id;
        state.level = intersects[0].object.intr.typeId;
        worldPosition = new THREE.Vector3();
        tweenFun(worldPosition.x, worldPosition.y, worldPosition.z);
        setTimeout(() => {
          if (starEl.value) starEl.value.style.display = "none";
        }, 0);
        setTimeout(() => {
          state.isShowSlect = false;
          state.isStarDetails = true;
          context.emit("activityBtn", 1);
        }, 1000);
      }
    };

    // 繁星背景
    const conststarForge = function () {
      const geometry = new THREE.BufferGeometry();
      let sumNum = window.innerWidth * 1.2;
      let array = [];
      for (let i = 0; i < sumNum; i++) {
        array.push(
          THREE.MathUtils.randFloatSpread(2000),
          THREE.MathUtils.randFloatSpread(2000),
          THREE.MathUtils.randFloatSpread(2000)
        );
      }
      const positions = new Float32Array(array);
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      const material = new THREE.PointsMaterial({
        color: 0xffffff,
      });
      const starField = new THREE.Points(geometry, material);
      scene.add(starField);
    };

    // 补间动画
    const tweenFun = function (x, y, z) {
      let tweenPlanet = new TWEEN.Tween(camera.position)
        .to({ x: x, y: y, z: z }, 1500)
        .easing(TWEEN.Easing.Quadratic.In);
      tweenPlanet.start();
    };

    // 清除模型
    const dispose = function (parent, child) {
      if (child.children.length) {
        let arr = child.children.filter((x) => x);
        arr.forEach((a) => {
          dispose(child, a);
        });
      }
      if (child instanceof THREE.Mesh || child instanceof THREE.Line) {
        if (child.material.map) child.material.map.dispose();
        child.material.dispose();
        child.geometry.dispose();
        child.geometry = null;
      } else if (child.material) {
        child.material.dispose();
      }
      child.remove();
      parent.remove(child);
    };
    // *********************************************************************************************************************************************************** //
    // **                                                           stup执行完执行下列方法                                                                         ** //
    // *********************************************************************************************************************************************************** //
    const handlerPath = (url) => {
      if (url != "") {
        window.open(url);
      }
    };
    onBeforeUnmount(() => {
      if (requestAni != null) {
        cancelAnimationFrame(requestAni);
        let arr = scene.children.filter((x) => x);
        arr.forEach((a) => {
          dispose(scene, a);
        });
        // setTimeout(() => { getStarList() });
      }
    });
    const onChage = (val) => {
      starFilter(val);
    };
    const onChage1 = (val) => {
      starFilterName(val);
    };
    const getDatoState = function () {
      proxy
        .$post(proxy.URL.daot.state)
        .then((res) => {
          if (res.code == 0 && res.data == null) {
            state.showBuyPopup = true
          }
        });
    };
    onMounted(() => {
      getDatoState()
      if (localStorage.getItem("ismobile") == 1) state.isShowSlect = false;
      props.showActivity == 0 ? state.activityTop = true : state.activityTop = false;
      init(); //初始化函数
      setTimeout(() => {
        getStarList();
      }); //获取数据接口
      setTimeout(() => {
        getAgeList();
      }); //获取时代列表
      setTimeout(() => {
        conststarForge();
      }); //繁星背景
    });
    return {
      starFilter,
      starInfoCard,
      starEl,
      selectHei,
      handlerClose,
      handlerClose1,
      handlerBuy,
      clearFun,
      ownerFun,
      threeStar,
      onChage,
      onChage1,
      handlerPath,
      chunk,
      starFilterName,
      add,
      starFilterId,
      getDatoState,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-select {
  width: 100%;

  .el-input__wrapper {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
    height: 34px;

    .el-input__inner {
      color: #fff;
      font-size: 14px;
    }
  }

  .is-focus {
    box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
  }
}

::v-deep .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
}
</style>
<style scoped>
@import "./global.css";

.star-main-box {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000000;
}

.node-info {
  position: fixed;
  top: 96px;
  left: 50%;
  width: 354px;
  height: 45px;
  background: url("../../public/images/homebtnbg.png") center no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);
  z-index: 10;
}

.notice-top {
  position: fixed;
  top: 96px;
  left: 50%;
  width: 343px;
  height: 44px;
  background: url("../../public/images/noticebg.png") center no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);
  z-index: 10;
}

.notice-top img {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin: 13px 8px 0 16px;
}

.notice-top div {
  flex: 1;
  margin-right: 16px;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  cursor: pointer;
}

.node-info div {
  height: 45px;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(89.38deg, #2dd177 0.13%, #ffe279 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
}

.select-box {
  position: absolute;
  top: 130px;
  left: 0;
  align-items: self-start;
  transition: all 0.8s;
}

.topmax {
  top: 182px;
}

.select-left {
  left: -200px;
}

.select-le {
  width: 200px;
  height: auto;
  overflow-y: auto;
  height: fit-content;
}

/*chrome 滚动条*/
.select-le::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

.select-le::-webkit-scrollbar-button {
  display: none;
}

.select-le::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 6px;
}

.select-le::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.3);
}

.select-le::-webkit-scrollbar-thumb {
  margin-right: 10px;
  background-color: #999;
  border-radius: 6px;
}

.select-le::-webkit-scrollbar-thumb:vertical {
  height: 6px;
}

.select-le::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}

.select-le::-webkit-scrollbar-resizer {
  background-color: #f5f5f5;
}

.select-ri {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid #3a3a3a;
  backdrop-filter: blur(15px);
  cursor: pointer;
}

.select-ri>svg {
  transition: all 0.8s;
}

.select-ri-rotate>svg {
  transform: rotate(180deg);
  transition: all 0.8s;
}

.select-list-box {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #3a3a3a;
  backdrop-filter: blur(15px);
  padding: 16px;
  margin-bottom: 10px;
}

.select-list {
  width: 100%;
  margin-bottom: 22px;
}

.select-list-box>.select-list:last-child {
  margin-bottom: 0px;
}

.select-list-title {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  font-family: "Nebula";
}

.select-list-select {
  width: 16px;
  height: 16px;
  opacity: 0.5;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.select-acitve {
  border: 1px solid #c9fa5b;
}

.select-acitve::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: #c9fa5b;
}

.coordinate-title {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
}

.coordinate-select {
  margin: 13px 0;
}

.coordinate-track-box {
  margin-top: 13px;
}

.coordinate-le {
  width: calc(50% - 4px);
  position: relative;
}

.coordinate-le-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  color: #b9b9b9;
}

.coordinate-le-numbe {
  width: 100%;
  height: 34px;
  border: 1px solid #424242;
  margin-top: 3px;
  padding: 0 10px;
  cursor: pointer;
}

.coordinate-le-numbe-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
}

.numbe-acetive {
  border: 1px solid #ffffff;
}

.numbe-acetive path {
  opacity: 1;
}

.numbe-acetive .coordinate-le-numbe-text {
  color: #ffffff;
}

.coordinate-position {
  width: 100%;
  max-height: 150px;
  position: absolute;
  top: 54px;
  left: 0;
  padding: 6px 0;
  background: rgba(201, 250, 91, 0.3);
  overflow-y: auto;
  z-index: 100;
}

/*chrome 滚动条*/
.coordinate-position::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

.coordinate-position::-webkit-scrollbar-button {
  display: none;
}

.coordinate-position::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 6px;
}

.coordinate-position::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 226, 127, 0.3);
}

.coordinate-position::-webkit-scrollbar-thumb {
  margin-right: 10px;
  background-color: #999;
  border-radius: 6px;
}

.coordinate-position::-webkit-scrollbar-thumb:vertical {
  height: 6px;
}

.coordinate-position::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-resizer {
  background-color: #f5f5f5;
}

.coordinate-text {
  width: 100%;
  height: 32px;
  padding-left: 10px;
  font-style: normal;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.coordinate-starid {
  margin-top: 16px;
}

.coordinate-starid-input {
  width: 100%;
  height: 34px;
  border: 1px solid #424242;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 6px;
}

.coordinate-starid-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
}

.coordinate-but {
  width: 100%;
  height: 33px;
  background: #c9fa5b;
  clip-path: polygon(0 0,
      calc(100% - 8px) 0,
      100% 8px,
      100% calc(100% + 8px),
      100% 100%,
      8px 100%,
      0 100%,
      0 8px);
  padding: 1px;
  margin-top: 17px;
  cursor: pointer;
}

.coordinate-but-bg {
  width: 100%;
  height: 100%;
  background: linear-gradient(-135deg, transparent 6px, #000000 0) bottom right;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  color: #c9fa5b;
  text-align: center;
  font-family: "Nebula";
}

.coordinate-clear {
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #c9fa5b;
  margin: 0 auto;
  margin-top: 17px;
  text-align: center;
  cursor: pointer;
}

.star-alert-box {
  position: fixed;
  top: 300px;
  left: 300px;
  z-index: 2;
  display: none;
}

.star-alert-rr>.star-alert-position {
  left: 199px;
  transform: rotate(180deg);
}

.star-alert-bb>.star-alert-position {
  left: -45px;
  top: 85px;
}

.star-alert-rb>.star-alert-position {
  left: 199px;
  top: 85px;
  transform: rotate(180deg);
}

.star-alert-content {
  position: relative;
  width: max-content;
  height: fit-content;
  background: rgba(201, 250, 91, 0.8);
  border-radius: 10px;
  padding: 16px 26px 6px 26px;
}

.star-alert-content::after {
  content: "";
  position: absolute;
  top: 12px;
  left: -12px;
  border-top: 12px solid transparent;

  border-bottom: 12px solid transparent;

  border-right: 12px solid rgba(201, 250, 91, 0.8);
}

.start1 {
  background: rgba(61, 61, 61, 0.8) !important;
}

.start1::after {
  content: "";
  position: absolute;
  top: 12px;
  left: -12px;
  border-top: 12px solid transparent;

  border-bottom: 12px solid transparent;

  border-right: 12px solid rgba(61, 61, 61, 0.8);
}

.start2 {
  background: rgba(227, 168, 15, 0.8) !important;
}

.start2::after {
  content: "";
  position: absolute;
  top: 12px;
  left: -12px;
  border-top: 12px solid transparent;

  border-bottom: 12px solid transparent;

  border-right: 12px solid rgba(227, 168, 15, 0.8);
}

.star-alert-text-box {
  margin-bottom: 10px;
}

.star-alert-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.star-alert-title>span {
  color: rgba(0, 0, 0, 1);
}

.start1 .star-alert-title {
  color: #bdbdbd !important;
}

.start1 .star-alert-title>span {
  color: #fff !important;
}

.star-alert-position {
  position: absolute;
  left: -45px;
  top: 10px;
}

.star-alert-position-dashed {
  width: 35px;
  border: 1px dashed #ffffff;
}

#footer {
  display: none;
}

/* 适配 */
@media (max-width: 1200px) {
  .select-le {
    width: 300px;
    height: auto;
  }

  .node-info {
    top: 68px;
    width: 90%;
  }

  .notice-top {
    top: 68px;
    width: 90%;
  }

  .select-left {
    left: -300px;
  }

  .select-le {
    height: 475px !important;
  }
}

@media (max-width: 767px) {
  .h5home-con {
    padding: 60px 16px 100px;
  }
}
</style>
